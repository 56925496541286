import { DbRecord, DbTag, DbTagGroup } from '@ds/shared-types';
import { Language } from 'aws-sdk/clients/support';
import { InvalidationOperationResult } from '../types';
import auth from './auth';
import { DS_CONFIG } from './config';

class Api {
  private baseUrl = DS_CONFIG.API;

  private formHeaders = async () => {
    const token = await auth.getToken();
    const headers: Record<string, string> = {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    return headers;
  };

  private getFullUrl(route: string): string {
    return `${this.baseUrl}/${route}`;
  }

  private async baseRequest<T>(
    makeRequest: () => Promise<{ data: T }>,
    errorValue: T,
  ) {
    try {
      return await makeRequest();
    } catch (error) {
      console.error(error);
      return { data: errorValue };
    }
  }

  public async storageAccess() {
    return await this.baseRequest<{
      keyId: string;
      key: string;
      token: string;
      bucketName: string;
    } | null>(async () => {
      const response = await fetch(this.getFullUrl('storage-access'), {
        headers: await this.formHeaders(),
      });
      const result = await response.json();
      return result;
    }, null);
  }

  /****** Tags ******/

  public async getAllTags() {
    return await this.baseRequest(async () => {
      const response = await fetch(this.getFullUrl('tag'), {
        headers: await this.formHeaders(),
      });
      return (await response.json()) as { data: DbTag[] };
    }, []);
  }

  public async postTag(item: DbTag) {
    return await this.baseRequest(async () => {
      const response = await fetch(this.getFullUrl('tag'), {
        headers: await this.formHeaders(),
        method: 'POST',
        body: JSON.stringify(item),
      });
      if (!response.ok) {
        throw new Error("Couldn't post Item");
      }
      return { data: null };
    }, null);
  }

  public async updateTag(item: Partial<DbTag>) {
    return await this.baseRequest(async () => {
      const response = await fetch(this.getFullUrl('tag'), {
        headers: await this.formHeaders(),
        method: 'PATCH',
        body: JSON.stringify(item),
      });
      if (!response.ok) {
        throw new Error("Couldn't patch Item");
      }
      return { data: null };
    }, null);
  }

  public async deleteTag(id: DbTag['id']) {
    return await this.baseRequest(async () => {
      const response = await fetch(this.getFullUrl('tag') + `?id=${id}`, {
        headers: await this.formHeaders(),
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error("Couldn't patch Item");
      }
      return { data: null };
    }, null);
  }

  /****** Tag Groups ******/

  public async getAllTagGroups() {
    return await this.baseRequest(async () => {
      const response = await fetch(this.getFullUrl('tag-group'), {
        headers: await this.formHeaders(),
      });
      return (await response.json()) as { data: DbTagGroup[] };
    }, []);
  }

  public async postTagGroup(item: DbTagGroup) {
    return await this.baseRequest(async () => {
      const response = await fetch(this.getFullUrl('tag-group'), {
        headers: await this.formHeaders(),
        method: 'POST',
        body: JSON.stringify(item),
      });
      if (!response.ok) {
        throw new Error("Couldn't post Item");
      }
      return { data: null };
    }, null);
  }

  public async updateTagGroup(item: Partial<DbTagGroup>) {
    return await this.baseRequest(async () => {
      const response = await fetch(this.getFullUrl('tag-group'), {
        headers: await this.formHeaders(),
        method: 'PATCH',
        body: JSON.stringify(item),
      });
      if (!response.ok) {
        throw new Error("Couldn't patch Item");
      }
      return { data: null };
    }, null);
  }

  public async deleteTagGroup(id: DbTagGroup['id']) {
    return await this.baseRequest(async () => {
      const response = await fetch(
        this.getFullUrl('tag-group') + `?id=${id}`,
        {
          headers: await this.formHeaders(),
          method: 'DELETE',
        },
      );
      if (!response.ok) {
        throw new Error("Couldn't patch Item");
      }
      return { data: null };
    }, null);
  }

  /****** Records ******/

  public async getAllRecords() {
    return await this.baseRequest(async () => {
      const response = await fetch(this.getFullUrl('record'), {
        headers: await this.formHeaders(),
      });
      return (await response.json()) as { data: DbRecord[] };
    }, []);
  }

  public async postRecord(item: DbRecord) {
    return await this.baseRequest(async () => {
      const response = await fetch(this.getFullUrl('record'), {
        headers: await this.formHeaders(),
        method: 'POST',
        body: JSON.stringify(item),
      });
      if (!response.ok) {
        throw new Error("Couldn't post Item");
      }
      return { data: null };
    }, null);
  }

  public async updateRecord(item: Partial<DbRecord>) {
    return await this.baseRequest(async () => {
      const response = await fetch(this.getFullUrl('record'), {
        headers: await this.formHeaders(),
        method: 'PATCH',
        body: JSON.stringify(item),
      });
      if (!response.ok) {
        throw new Error("Couldn't patch Item");
      }
      return { data: null };
    }, null);
  }

  public async deleteRecord(id: DbRecord['id']) {
    return await this.baseRequest(async () => {
      const response = await fetch(
        this.getFullUrl('record') + `?id=${id}`,
        {
          headers: await this.formHeaders(),
          method: 'DELETE',
        },
      );
      if (!response.ok) {
        throw new Error("Couldn't patch Item");
      }
      return { data: null };
    }, null);
  }

  /****** Misc ******/

  public async createInvalidation() {
    return await this.baseRequest<InvalidationOperationResult | null>(
      async () => {
        const response = await fetch(this.getFullUrl('invalidation'), {
          headers: await this.formHeaders(),
          method: 'POST',
        });
        if (!response.ok) {
          throw new Error("Couldn't create invalidation");
        }
        const result = await response.json();
        return result;
      },
      null,
    );
  }

  public async getInvalidation(id: string) {
    return await this.baseRequest<InvalidationOperationResult | null>(
      async () => {
        const response = await fetch(
          this.getFullUrl('invalidation') + `?id=${id}`,
          {
            headers: await this.formHeaders(),
            method: 'GET',
          },
        );
        if (!response.ok) {
          throw new Error("Couldn't create invalidation");
        }
        const result = await response.json();
        return result;
      },
      null,
    );
  }

  public async refillCache(payload: {
    items: {
      lang: Language;
      pathTokens: string[];
      queryParameters: Record<string, string | undefined>;
    }[];
    refreshDataCache?: boolean;
  }) {
    return await this.baseRequest<null>(async () => {
      const response = await fetch(this.getFullUrl('refill-cache'), {
        headers: await this.formHeaders(),
        method: 'POST',
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Couldn't refill cache");
      }
      const result = await response.json();
      return result;
    }, null);
  }
}

export const api = new Api();
