import { extractFileExtension, isVideo } from '@ds/shared-utils';
import { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    objectFit: 'contain',
  },
  showFileRoot: {
    zIndex: 99999,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000b8',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overscrollBehavior: 'none',
  },
  filePreviewRoot: {
    overflow: 'auto',
    maxHeight: '100%',
  },
  closeFilePreviewButton: {
    position: 'absolute',
    left: '25px',
    bottom: '15px',
    backgroundColor: '#6c6c6cc9',
    padding: '10px',
    color: '#fff',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
});

export const FilePreview: React.FC<{
  file: string;
  width: number;
  height: number;
  extension?: string;
}> = ({ file, extension: explicitExtension, height, width }) => {
  const classes = useStyles();

  const [showFile, setShowFile] = useState(false);

  const extension = explicitExtension || extractFileExtension(file);
  const isVideoFile = extension && isVideo(extension);

  const handleHideFile = useCallback(() => {
    window.document
      .getElementsByTagName('body')?.[0]
      .classList.remove(classes.overflowHidden);
    setShowFile(false);
  }, [setShowFile, classes.overflowHidden]);

  const handleShowFile = useCallback(() => {
    window.document
      .getElementsByTagName('body')?.[0]
      .classList.add(classes.overflowHidden);
    setShowFile(true);
  }, [setShowFile, classes.overflowHidden]);

  return (
    <div className={classes.root}>
      {isVideoFile ? (
        <video key={file} width={width} height="150px" controls>
          <source src={file} type="video/mp4" />
        </video>
      ) : null}

      {!isVideoFile ? (
        <img
          className={classes.image}
          width={width}
          height={height}
          src={file}
          onClick={handleShowFile}
        ></img>
      ) : null}

      {showFile && (
        <div
          className={classes.showFileRoot}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={handleHideFile}
        >
          <button
            className={classes.closeFilePreviewButton}
            onClick={handleHideFile}
          >
            CLOSE
          </button>
          <div className={classes.filePreviewRoot}>
            <img src={file}></img>
          </div>
        </div>
      )}
    </div>
  );
};
