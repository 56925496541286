import { DbRecord, Language } from '@ds/shared-types';
import { api } from '../../core/api';
import { getFileFunctions } from '../../utils/file.utils';
import { FileInfo, RecordInfo } from './EditRecord.types';
import { processFiles } from './processFiles';

export async function updateRecord({
  recordInfo,
  recordToEdit,

  filesEn,
  filesUa,
  filesRu,
}: {
  recordInfo: RecordInfo;
  recordToEdit: DbRecord;

  filesEn: FileInfo[];
  filesUa: FileInfo[];
  filesRu: FileInfo[];
}): Promise<DbRecord> {
  const changes: RecordInfo = {};
  Object.entries(recordInfo).forEach(([keyRaw, value]) => {
    const key = keyRaw as keyof RecordInfo;
    if (recordToEdit[key] !== value) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      changes[key] = value as any;
    }
  });

  const updatedAt = Date.now();

  const needToProcessFiles =
    filesEn.length || filesRu.length || filesRu.length;

  if (needToProcessFiles) {
    const { uploadFile, deleteFile } = await getFileFunctions();

    const processFilesForLang = async (
      lang: Language,
      files: FileInfo[],
    ) => {
      const { newFilesStr } = await processFiles({
        files,
        recordId: recordToEdit.id,
        lang,
        deleteFile,
        uploadFile,
      });
      return newFilesStr.filter((f) => f);
    };

    if (filesEn.find((f) => f.mutation)) {
      changes.filesEn = await processFilesForLang(Language.en, filesEn);
    }
    if (filesUa.find((f) => f.mutation)) {
      changes.filesUa = await processFilesForLang(Language.ua, filesUa);
    }
    if (filesRu.find((f) => f.mutation)) {
      changes.filesRu = await processFilesForLang(Language.ru, filesRu);
    }
  }

  if (!Object.keys(changes).length) {
    return recordToEdit;
  }

  await api.updateRecord({
    ...changes,
    id: recordToEdit.id,
    updatedAt,
  });

  return {
    ...recordToEdit,
    ...changes,
  };
}
