import { Language } from '@ds/shared-types';

export function getDataForLanguage<T = string>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
  lang: Language,
  propName: string,
): T {
  const langPart = lang.charAt(0).toUpperCase() + lang.slice(1);
  return obj[propName + langPart];
}
