import { Language } from '@ds/shared-types';
import { extractFileExtension } from '@ds/shared-utils';
import {
  extractFileNameWithoutPath,
  getFileFunctions,
} from '../../utils/file.utils';
import { FileInfo } from './EditRecord.types';

export async function processFiles({
  files,
  recordId,
  lang,
  deleteFile,
  uploadFile,
}: {
  files: FileInfo[];
  recordId: string;
  lang: Language;
} & Awaited<ReturnType<typeof getFileFunctions>>) {
  const newFilesStr: string[] = [];

  await Promise.all(
    files
      .sort((a, b) => a.index - b.index)
      .map(async (file, index) => {
        if (!file.mutation && file.fileStr) {
          newFilesStr[index] = file.fileStr;
          return;
        }

        const deletePrevious =
          file.mutation === 'deleted' || file.mutation === 'updated';

        const uploadNew =
          file.mutation === 'created' || file.mutation === 'updated';

        if (deletePrevious) {
          await deleteFile(
            extractFileNameWithoutPath(file.fileStr as string),
          );
        }

        if (uploadNew) {
          if (!file.fileRaw) {
            console.error('File is invalid', JSON.stringify(file));
            return;
          }
          const extension = extractFileExtension(file.fileRaw.name);

          const { Location } = await uploadFile({
            extension,
            file: file.fileRaw,
            id: recordId,
            index,
            lang,
            updatedAt: Date.now(),
          });

          newFilesStr[index] = Location;
        }
      }),
  );

  return { newFilesStr };
}
