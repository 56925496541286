import { DbItemType, DbRecord, Language } from '@ds/shared-types';
import { v4 as uuidV4 } from 'uuid';
import { api } from '../../core/api';
import { getFileFunctions } from '../../utils/file.utils';
import { FileInfo, RecordInfo } from './EditRecord.types';
import { processFiles } from './processFiles';

export async function postRecord({
  recordInfo,
  filesEn,
  filesUa,
  filesRu,
}: {
  recordInfo: RecordInfo;
  filesEn: FileInfo[];
  filesUa: FileInfo[];
  filesRu: FileInfo[];
}) {
  if (!filesRu.length) {
    throw new Error('Files are not selected');
  }
  const id = uuidV4();

  const { uploadFile, deleteFile } = await getFileFunctions();

  const processFilesForLang = async (
    lang: Language,
    files: FileInfo[],
  ) => {
    const { newFilesStr } = await processFiles({
      files,
      recordId: id,
      lang,
      deleteFile,
      uploadFile,
    });
    return newFilesStr;
  };

  const updatedAt = Date.now();

  const record: DbRecord = {
    id,
    type: DbItemType.record,
    tags: recordInfo.tags as string[],

    filesEn: await processFilesForLang(Language.en, filesEn),
    filesUa: await processFilesForLang(Language.ua, filesUa),
    filesRu: await processFilesForLang(Language.ru, filesRu),

    titleEn: recordInfo.titleEn as string,
    titleUa: recordInfo.titleUa as string,
    titleRu: recordInfo.titleRu as string,

    textEn: recordInfo.textEn as string,
    textUa: recordInfo.textUa as string,
    textRu: recordInfo.textRu as string,

    updatedAt,
  };

  await api.postRecord(record);

  return record;
}
