import { DbRecord, DbTag, DbTagGroup } from '@ds/shared-types';
import React, { ReactElement, useMemo, useState } from 'react';
import { csn } from '../../utils/csn.utils';
import { Loader } from '../Loader';
import { useEditRecordStyles } from './EditRecord.styles';
import { FileInfo, RecordInfo } from './EditRecord.types';
import { EditRecordFiles } from './EditRecordFiles';
import { submitRecord } from './submitRecord';

export const EditRecord: React.FC<{
  tags: DbTag[];
  tagsGroups: DbTagGroup[];
  onClose: (newRecord?: DbRecord) => void;
  recordToEdit?: DbRecord;
}> = ({ onClose, tags, tagsGroups, recordToEdit }) => {
  const recordToEditInfo = useMemo<Partial<DbRecord>>(() => {
    const { id, ...rest } = recordToEdit || {};
    return rest;
  }, [recordToEdit]);

  const { initialFilesRu, initialFilesEn, initialFilesUa } = useMemo<{
    initialFilesRu: FileInfo[];
    initialFilesEn: FileInfo[];
    initialFilesUa: FileInfo[];
  }>(() => {
    return {
      initialFilesEn: getInitialFiles(recordToEditInfo?.filesEn),
      initialFilesUa: getInitialFiles(recordToEditInfo?.filesUa),
      initialFilesRu: getInitialFiles(recordToEditInfo?.filesRu),
    };
  }, [
    recordToEditInfo?.filesEn,
    recordToEditInfo?.filesUa,
    recordToEditInfo?.filesRu,
  ]);

  const [filesRu, setFilesRu] = useState<FileInfo[]>(initialFilesRu);
  const [filesEn, setFilesEn] = useState<FileInfo[]>(initialFilesEn);
  const [filesUa, setFilesUa] = useState<FileInfo[]>(initialFilesUa);

  const [recordInfo, setRecordInfo] =
    useState<RecordInfo>(recordToEditInfo);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateRecordInfo = (data: RecordInfo) =>
    setRecordInfo((currentData) => ({ ...currentData, ...data }));

  const [selectedTagGroup, setSelectedTagGroup] =
    useState<DbTagGroup | null>(null);
  const [_selectedTag, _setSelectedTag] = useState<DbTag | null>(null);

  const classes = useEditRecordStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          {recordToEdit
            ? `Edit record with ID: "${recordToEdit.id}"`
            : 'Create new record'}
        </div>

        <div
          className={classes.headerCloseButton}
          onClick={() => onClose()}
        >
          X
        </div>
      </div>
      {isSubmitting && <Loader />}
      <div className={classes.blockGeneralInfo}>
        <div className={classes.optionSelection}>
          <div className={classes.marginRight5px}>Tag:</div>
          <select
            className={classes.marginRight5px}
            value={selectedTagGroup?.id}
            onChange={(e) =>
              setSelectedTagGroup(
                tagsGroups.find((tg) => tg.id === e.target.value) || null,
              )
            }
          >
            <option value={''}>{`<select tag group>`}</option>
            {tagsGroups.map((tg) => (
              <option key={tg.id} value={tg.id}>
                {tg.id}
              </option>
            ))}
          </select>
          <div className={classes.marginRight5px}>{' > '}</div>
          <select
            className={classes.marginRight5px}
            onChange={(e) =>
              updateRecordInfo({
                tags: [
                  ...new Set([
                    ...(recordInfo?.tags || []),
                    e.target.value,
                  ]),
                ],
              })
            }
          >
            <option value={''}>{`<select tag group>`}</option>
            {tags
              .filter(
                (t) =>
                  t.tagGroupId === selectedTagGroup?.id &&
                  !recordInfo.tags?.includes(t.id),
              )
              .map((t) => (
                <option key={t.id} value={t.id}>
                  {t.id}
                </option>
              ))}
          </select>
          <div className={classes.selectedTagsCaption}>Selected tags:</div>
          {recordInfo?.tags?.map((tag) => {
            return (
              <div
                key={tag}
                className={csn(classes.selectedTag)}
                onClick={() => {
                  updateRecordInfo({
                    tags: recordInfo?.tags?.filter((t) => t !== tag) || [],
                  });
                }}
              >
                {tag}
              </div>
            );
          })}
        </div>

        <br />
      </div>

      <div className={classes.rowLanguageRelatedData}>
        {getLanguageRelatedContent({
          classes,
          files: filesEn,
          flagSymbol: '🇬🇧',
          lang: 'En',
          recordInfo,
          onSetFiles: setFilesEn,
          onUpdateRecordInfo: updateRecordInfo,
        })}

        {getLanguageRelatedContent({
          classes,
          files: filesUa,
          flagSymbol: '🇺🇦',
          lang: 'Ua',
          recordInfo,
          onSetFiles: setFilesUa,
          onUpdateRecordInfo: updateRecordInfo,
        })}

        {getLanguageRelatedContent({
          classes,
          files: filesRu,
          flagSymbol: '🇷🇺',
          lang: 'Ru',
          recordInfo,
          onSetFiles: setFilesRu,
          onUpdateRecordInfo: updateRecordInfo,
        })}
      </div>

      <div className={classes.rowControlButtons}>
        <button
          className={classes.controlButton}
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          className={classes.controlButton}
          onClick={() => {
            setIsSubmitting(true);
            submitRecord({
              recordInfo: recordInfo,
              filesRu,
              filesEn,
              filesUa,
              recordToEdit: recordToEdit,
            })
              .then(({ success, processedRecord }) => {
                if (success) {
                  onClose(processedRecord);
                }
              })
              .catch((e) => {
                console.error(e);
                alert('Failed to save');
              })
              .finally(() => setIsSubmitting(false));
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

function getLanguageRelatedContent({
  classes,
  files,
  recordInfo,
  flagSymbol,
  lang,
  onUpdateRecordInfo,
  onSetFiles,
}: {
  classes: ReturnType<typeof useEditRecordStyles>;
  files: FileInfo[];
  flagSymbol: string;
  lang: 'En' | 'Ua' | 'Ru';
  recordInfo: Partial<Omit<DbRecord, 'id'>>;
  onUpdateRecordInfo: (data: RecordInfo) => void;
  onSetFiles: (files: FileInfo[]) => void;
}): ReactElement {
  return (
    <div className={classes.nestedColumnLanguageRelatedData}>
      {`${flagSymbol}Title (${lang}):`}
      <input
        type="text"
        value={recordInfo[`title${lang}`] || ''}
        onChange={(e) =>
          onUpdateRecordInfo({ [`title${lang}`]: e.target.value })
        }
      />
      <br />
      {`${flagSymbol}Text (${lang}):`}
      <textarea
        value={recordInfo[`text${lang}`] || ''}
        cols={30}
        rows={10}
        placeholder="Rus text goes here"
        onChange={(e) => {
          onUpdateRecordInfo({ [`text${lang}`]: e.target.value });
        }}
      />
      <br />
      {`${flagSymbol}Files (${lang}):`}
      <EditRecordFiles
        filesInfo={files}
        onFileChange={(fileInfo) =>
          onFileChange({
            fileInfo,
            files: files,
            onSetFiles,
          })
        }
      />
    </div>
  );
}

function getInitialFiles(files: string[] = []): FileInfo[] {
  return files
    .filter((f) => f)
    .map(
      (file, i) =>
        ({
          index: i,
          fileStr: file,
        } as FileInfo),
    );
}

function onFileChange({
  fileInfo,
  files,
  onSetFiles,
}: {
  fileInfo: FileInfo;
  files: FileInfo[];
  onSetFiles: (files: FileInfo[]) => void;
}) {
  const prevFileInfo = files.find((f) => f.index === fileInfo.index);

  if (!prevFileInfo && fileInfo.mutation === 'created') {
    onSetFiles([...files, fileInfo]);
    return;
  }

  if (
    prevFileInfo?.mutation === 'created' &&
    fileInfo.mutation === 'deleted'
  ) {
    const newFilesInfo = files.filter((fi) => fi.index !== fileInfo.index);
    onSetFiles(newFilesInfo);
    return;
  }

  const newFilesInfo = files.map((fi) => {
    if (fi.index !== fileInfo.index) {
      return fi;
    }
    return fileInfo;
  });
  onSetFiles(newFilesInfo);
}
