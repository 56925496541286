import { extractFileExtension } from '@ds/shared-utils';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FilePreview } from '../FilePreview';
import { FileInfo } from './EditRecord.types';

export const useEditRecordFilesStyles = createUseStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  container: {
    padding: '5px',
    border: '1px solid gray',
    borderRadius: '4px',
    margin: '0 4px 4px 0',
  },
  fileWrapper: {
    backgroundColor: 'gray',
    margin: '-5px -5px 5px',
    position: 'relative',
  },
  removeFileButton: {
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
  newFieldButton: {
    width: '263px',
    height: '180px',
  },
  fileIsNotSelectedLabel: {
    position: 'absolute',
    background: '#ffffffab',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

export const EditRecordFiles: React.FC<{
  filesInfo?: FileInfo[];
  onFileChange: (fileInfo: FileInfo) => void;
}> = ({ filesInfo = [], onFileChange }) => {
  const classes = useEditRecordFilesStyles();

  const [isAdding, setIsAdding] = useState(false);

  return (
    <div className={classes.root}>
      {filesInfo.map((fileInfo, i) => {
        if (fileInfo.mutation === 'deleted') {
          return null;
        }

        const fileStr =
          fileInfo.mutation && fileInfo.fileRaw
            ? URL.createObjectURL(fileInfo.fileRaw)
            : fileInfo.fileStr;

        if (!fileStr) {
          return null;
        }

        const extension = fileInfo.fileRaw
          ? extractFileExtension(fileInfo.fileRaw.name)
          : undefined;

        return (
          <div key={i} className={classes.container}>
            <div className={classes.fileWrapper}>
              <FilePreview
                file={fileStr}
                extension={extension}
                height={150}
                width={250}
              />
              <button
                className={classes.removeFileButton}
                title="Remove file"
                onClick={() => {
                  onFileChange({
                    ...fileInfo,
                    mutation: 'deleted',
                  });
                }}
              >
                X
              </button>
            </div>

            <input
              type="file"
              onChange={(e) => {
                const file = (e?.target?.files || [])[0];
                if (!file) {
                  return;
                }

                onFileChange({
                  ...fileInfo,
                  fileRaw: file,
                  mutation:
                    fileInfo.mutation === 'created'
                      ? 'created'
                      : 'updated',
                });
              }}
            />
          </div>
        );
      })}

      {isAdding ? (
        <div className={classes.container}>
          <div className={classes.fileWrapper}>
            <FilePreview file={''} height={150} width={250} />
            <div className={classes.fileIsNotSelectedLabel}>
              <button onClick={() => setIsAdding(false)}>Cancel</button>
            </div>
          </div>
          <input
            type="file"
            onChange={(e) => {
              const file = (e?.target?.files || [])[0];
              if (!file) {
                return;
              }

              onFileChange({
                index: filesInfo.length,
                fileRaw: file,
                mutation: 'created',
              });
              setIsAdding(false);
            }}
          />
        </div>
      ) : (
        <button
          className={classes.newFieldButton}
          onClick={() => setIsAdding(true)}
        >
          Add New File
        </button>
      )}
    </div>
  );
};
