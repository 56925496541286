interface ClassMap {
  [key: string]: boolean | undefined | null;
}

export function csn(
  ...classes: Array<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    string | null | undefined | ClassMap | false | [string, any]
  >
): string {
  return classes
    .map((item) => {
      if (item) {
        if (typeof item === 'object') {
          if (Array.isArray(item)) {
            if (item[0] && item[1]) {
              return item[0];
            }
          } else {
            return Object.keys(item as ClassMap)
              .filter((key) => (item as ClassMap)[key])
              .join(' ');
          }
        } else {
          return item;
        }
      }
      return '';
    })
    .filter((item) => item)
    .join(' ');
}
