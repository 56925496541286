/* eslint-disable no-case-declarations */
import URLSearchParams from '@ungap/url-search-params';
import { MapLike } from 'typescript';
import { DS_CONFIG } from './config';
export interface IdTokenPayload {
  exp: number;
  'cognito:groups': string[];
  username: string;
}

interface OAuth2TokenResponse {
  error?: string;
  id_token?: string;
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  token_type?: string;
}

const lsRefreshToken = 'auth_refresh_token';
const lsAccessToken = 'auth_access_token';

class Auth {
  public isAuthorized = false;
  public verifyingOnLogin = false;

  private config = {
    clientId: DS_CONFIG.COGNITO_CLIENT_ID,
    domain: DS_CONFIG.COGNITO_DOMAIN,
    redirectLogin: '/loggedIn',
    redirectLogout: '/loggedOut',
    responseType: 'code',
  };
  private token = '';
  private exp = 0;
  private username = '';
  private isAdmin = false;

  constructor() {
    this.initFromStorage();

    const currentPath = window.location.pathname;

    switch (currentPath) {
      case this.config.redirectLogin:
        const code = new URLSearchParams(window.location.search).get(
          'code',
        );
        this.verifyingOnLogin = true;
        this.getTokensFromCode(code || '')
          .then((payload) => {
            if (payload.error) {
              throw new Error(payload.error);
            } else {
              this.saveTokensToStorage(payload);
            }
          })
          .finally(() => {
            this.verifyingOnLogin = false;
            window.location.replace(window.location.origin);
          });
        break;

      case this.config.redirectLogout:
        this.clearStorage();
        window.location.replace(window.location.origin);
        break;
    }
  }

  public login = () => {
    window.location.assign(this.getLoginUrl());
  };

  public logout = () => {
    window.location.assign(this.getLogoutUrl());
  };

  public getIsAdmin = () => this.isAdmin;

  public getUsername = () => this.username;

  public getToken = async () => {
    if (!this.token) {
      return '';
    }

    if (Date.now() < this.exp * 1000) {
      return this.token;
    }

    const refreshToken = localStorage.getItem(lsRefreshToken);
    if (!refreshToken) {
      this.clearStorage();
      window.location.reload();
      return ''; // this is for intellisense: we always return string
    }

    let payload: OAuth2TokenResponse;
    try {
      payload = await this.getRefreshedTokens(refreshToken);
    } catch (e) {
      // In case of network problems, we just skip until the next time
      console.error(e);
      return this.token;
    }

    // this part also includes refresh token expiration flow (error = 'invalid_grant')
    if (payload.error) {
      this.clearStorage();
      window.location.reload();
      return ''; // this is for intellisense: we always return string
    }

    this.saveTokensToStorage(payload);
    this.initFromStorage();
    return this.token;
  };

  private initFromStorage = () => {
    this.username = '';
    this.exp = 0;
    this.isAuthorized = false;
    this.isAdmin = false;
    this.token = localStorage.getItem(lsAccessToken) || '';

    try {
      if (this.token) {
        const { exp, username } = this.getTokenPayload(this.token);
        this.exp = exp;
        this.username = username;
        this.isAuthorized = true;
      }
    } catch (e) {
      console.error(e);
      this.token = '';
    }
  };

  private saveTokensToStorage = ({
    // id_token,
    access_token,
    refresh_token,
  }: OAuth2TokenResponse) => {
    // localStorage.setItem(lsIdToken, id_token || '');
    localStorage.setItem(lsAccessToken, access_token || '');
    // we have refresh token only after login request
    if (refresh_token) {
      localStorage.setItem(lsRefreshToken, refresh_token);
    }
  };

  private clearStorage = () => {
    // localStorage.setItem(lsIdToken, '');
    localStorage.setItem(lsAccessToken, '');
    localStorage.setItem(lsRefreshToken, '');
  };

  private getAuthUrl = (path: string, params: MapLike<unknown>) => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      searchParams.set(key, (params as any)[key]),
    );
    return `https://${
      this.config.domain
    }/${path}?${searchParams.toString()}`;
  };

  private getLoginUrl = () =>
    this.getAuthUrl('login', {
      client_id: this.config.clientId,
      redirect_uri: window.location.origin + this.config.redirectLogin,
      response_type: this.config.responseType,
    });

  private getLogoutUrl = () =>
    this.getAuthUrl('logout', {
      client_id: this.config.clientId,
      logout_uri: window.location.origin + this.config.redirectLogout,
    });

  private getTokenPayload = (token: string) => {
    const payloadPart = token.split('.')[1];
    if (payloadPart) {
      return JSON.parse(
        window.atob(payloadPart.replace(/-/g, '+').replace(/_/g, '/')),
      ) as IdTokenPayload;
    } else {
      throw new Error('Invalid token');
    }
  };

  private fetchUrlEncoded = (
    path: string,
    params: MapLike<unknown>,
  ): Promise<Response> => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      searchParams.set(key, (params as any)[key]),
    );

    return fetch(`https://${this.config.domain}/${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: searchParams,
    });
  };

  private getTokensFromCode = (code: string) =>
    this.fetchUrlEncoded(`oauth2/token`, {
      grant_type: 'authorization_code',
      client_id: this.config.clientId,
      redirect_uri: window.location.origin + this.config.redirectLogin,
      code,
    }).then((response) => response.json() as OAuth2TokenResponse);

  private getRefreshedTokens = (refreshToken: string) =>
    this.fetchUrlEncoded(`oauth2/token`, {
      grant_type: 'refresh_token',
      client_id: this.config.clientId,
      redirect_uri: window.location.origin + this.config.redirectLogin,
      refresh_token: refreshToken,
    }).then((response) => response.json() as OAuth2TokenResponse);
}

const auth = new Auth();

export default auth;
