import { DbRecord } from '@ds/shared-types';
import { S3 } from 'aws-sdk';
import { Language } from 'aws-sdk/clients/support';
import { api } from '../core/api';

export async function getFileFunctions() {
  const { data: storageAccessData } = await api.storageAccess();
  if (!storageAccessData) {
    throw new Error('Cannot get storage access data');
  }

  const { bucketName, key, keyId, token } = storageAccessData;
  const s3 = new S3({
    credentials: {
      accessKeyId: keyId,
      secretAccessKey: key,
      sessionToken: token,
    },
  });

  const uploadFile = async ({
    file,
    id,
    lang,
    extension,
    index,
    updatedAt,
  }: {
    file: File;
    lang: Language;
    id: DbRecord['id'];
    index: number;
    extension: string;
    updatedAt: DbRecord['updatedAt'];
  }) => {
    return s3
      .upload({
        Bucket: bucketName,
        Key: `${lang}-${id}-${index}-${updatedAt}.${extension}`,
        ContentType: file.type,
        Body: file,
      })
      .promise();
  };

  const deleteFile = async (file: string) => {
    return s3
      .deleteObject({
        Bucket: bucketName,
        Key: file,
      })
      .promise();
  };

  return { uploadFile, deleteFile };
}

export function extractFileNameWithoutPath(fullFileName: string): string {
  return fullFileName?.split('/').pop() || '';
}
