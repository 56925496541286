import {
  DbItemType,
  DbTag,
  DbTagEntity,
  DbTagGroup,
} from '@ds/shared-types';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { api } from '../core/api';
import { csn } from '../utils/csn.utils';
import { Loader } from './Loader';
import { useTagsStyles } from './Tags.styles';

const EditTagEntity: React.FC<{
  title: string;
  data: Partial<DbTagEntity>;
  onSetData: (data: Partial<DbTagEntity>) => void;
  isExisting?: boolean;
  onClose: () => void;
  onSave: (data: DbTagEntity) => void;
  additionalContent?: React.ReactElement;
}> = ({
  title,
  data,
  onSetData,
  onClose,
  onSave,
  additionalContent,
  isExisting,
}) => {
  const classes = useTagsStyles();

  const handleSetData = (changes: Partial<DbTagEntity>) =>
    onSetData({ ...data, ...changes });

  const getRow = ({
    fieldName,
    caption,
  }: {
    fieldName: keyof Omit<DbTagEntity, 'disabled'>;
    caption: string;
  }) => (
    <div className={classes.editTagEntityRow}>
      <div className={classes.editTagEntityLabel}>{caption}:</div>
      <input
        className={classes.editTagEntityInput}
        type="text"
        value={data[fieldName] || ''}
        onChange={(e) => handleSetData({ [fieldName]: e.target.value })}
      />
    </div>
  );

  return (
    <div className={classes.editTagEntityRoot}>
      <div className={classes.editTagEntityContent}>
        <div className={classes.editTagEntityTitle}>{title}</div>

        <div className={classes.editTagEntityRow}>
          <div className={classes.editTagEntityLabel}>ID:</div>
          <input
            className={classes.editTagEntityInput}
            type="text"
            disabled={isExisting}
            value={data.id || ''}
            title={isExisting ? 'Cannot edit existing ID' : ''}
            onChange={(e) =>
              handleSetData({ id: transformToIdValue(e.target.value) })
            }
          />
        </div>
        {getRow({ fieldName: 'titleEn', caption: 'Title En' })}
        {getRow({ fieldName: 'titleUa', caption: 'Title Ua' })}
        {getRow({ fieldName: 'titleRu', caption: 'Title Ru' })}
        <div className={classes.editTagEntityRow}>
          <div className={classes.editTagEntityLabel}>Sort Order:</div>
          <input
            className={classes.editTagEntityInput}
            type="number"
            value={data.sortOrder || 0}
            onChange={(e) =>
              handleSetData({ sortOrder: e.target.valueAsNumber })
            }
          />
        </div>

        {additionalContent}

        <div className={classes.editTagEntityRow}>
          <div className={classes.editTagEntityLabel}>Disabled:</div>
          <input
            type="checkbox"
            className={classes.editTagEntityInput}
            checked={data.disabled || false}
            onChange={(e) => handleSetData({ disabled: e.target.checked })}
          />
        </div>
      </div>

      <div className={classes.editTagEntityFooter}>
        <button
          className={classes.editTagEntityFooterButton}
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className={classes.editTagEntityFooterButton}
          onClick={() => {
            if (
              !data.id?.trim() ||
              !data.titleEn?.trim() ||
              !data.titleUa?.trim() ||
              !data.titleRu?.trim()
            ) {
              alert('Not all fields are set');
              return;
            }

            onSave({ ...(data as DbTagEntity) });
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

const TagEntityListItem: React.FC<{
  item: DbTagEntity;
  onDelete: () => void;
  onEdit: () => void;
  firsColumnContent?: ReactElement;
}> = ({ item, onDelete, onEdit, firsColumnContent }) => {
  const classes = useTagsStyles();

  return (
    <div className={classes.tagEntityListItemRoot}>
      {firsColumnContent}
      <div className={classes.tagEntityListItemValue} title="ID">
        🆔 {item.id}
      </div>
      <div className={classes.tagEntityListItemValue} title="Title EN">
        🇬🇧 {item.titleEn}
      </div>
      <div className={classes.tagEntityListItemValue} title="Title UA">
        🇺🇦 {item.titleUa}
      </div>
      <div className={classes.tagEntityListItemValue} title="Title RUS">
        🇷🇺 {item.titleRu}
      </div>

      <div
        className={csn(
          classes.tagEntityListItemValue,
          classes.tagEntityListItemValueHalfWidth,
        )}
      >
        {item.disabled ? <div>🚫 disabled</div> : <div>✅ enabled</div>}
      </div>

      <div
        className={csn(
          classes.tagEntityListItemValue,
          classes.tagEntityListItemValueThirdOfWidth,
        )}
        title="Sort Order"
      >
        {item.sortOrder ? `🔢 ${item.sortOrder}` : '-'}
      </div>
      <button onClick={onEdit}>Edit</button>
      <button onClick={onDelete}>Delete</button>
    </div>
  );
};

export const Tags: React.FC<{
  tagGroups: DbTagGroup[];
  tags: DbTag[];
  onTagDataChange: () => void;
  onTagGroupDataChange: () => void;
  onClose: () => void;
}> = ({
  tags,
  tagGroups,
  onClose,
  onTagDataChange,
  onTagGroupDataChange,
}) => {
  const classes = useTagsStyles();

  const [view, setView] = useState<'tags' | 'tagGroups'>('tags');

  const [isLoading, setIsLoading] = useState(false);

  const [editingTagInfo, setEditingTagInfo] = useState<{
    tagData?: Partial<DbTag>;
    isExisting?: boolean;
  }>({});

  const [editingTagGroup, setEditingTagGroup] = useState<{
    tagGroupData?: Partial<DbTagGroup>;
    isExisting?: boolean;
  }>({});

  const handleShowTags = useCallback(() => setView('tags'), [setView]);
  const handleShowTagGroups = useCallback(
    () => setView('tagGroups'),
    [setView],
  );

  const preparedTags = useMemo(
    () =>
      tags
        .sort(
          (a, b) =>
            (a.sortOrder || Number.MAX_SAFE_INTEGER) -
            (b.sortOrder || Number.MAX_SAFE_INTEGER),
        )
        .sort((a, b) => a.tagGroupId.localeCompare(b.tagGroupId)),
    [tags],
  );

  const preparedTagGroups = useMemo(
    () =>
      tagGroups.sort(
        (a, b) =>
          (a.sortOrder || Number.MAX_SAFE_INTEGER) -
          (b.sortOrder || Number.MAX_SAFE_INTEGER),
      ),
    [tagGroups],
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.blockHeaderLeftGroup}>
          <div className={classes.caption}>Tag Groups and Tags</div>
          <button
            className={classes.buttonAdd}
            onClick={() => {
              setEditingTagGroup(() => ({}));
              setEditingTagInfo(() => ({ tagData: {} }));
            }}
          >
            Add Tag
          </button>
          <button
            className={classes.buttonAdd}
            onClick={() => {
              setEditingTagInfo(() => ({}));
              setEditingTagGroup(() => ({ tagGroupData: {} }));
            }}
          >
            Add Tag Group
          </button>

          <div className={classes.blockHeaderViews}>
            <button
              className={csn(classes.buttonView, [
                classes.buttonViewSelected,
                view === 'tags',
              ])}
              onClick={handleShowTags}
            >
              Tags
            </button>
            <button
              className={csn(classes.buttonView, [
                classes.buttonViewSelected,
                view === 'tagGroups',
              ])}
              onClick={handleShowTagGroups}
            >
              Tag Groups
            </button>
          </div>
        </div>

        <div
          className={classes.headerCloseButton}
          onClick={() => onClose()}
        >
          X
        </div>
      </div>

      {view === 'tagGroups' ? (
        <div>
          {preparedTagGroups.map((tg) => (
            <TagEntityListItem
              key={tg.id}
              item={tg}
              onDelete={() => {
                if (
                  prompt(
                    `Are you sure you want to delete tag group "${tg.id}" (yes/no)`,
                  )?.toLowerCase() === 'yes'
                ) {
                  if (tags.find((t) => t.tagGroupId === tg.id)) {
                    alert(
                      'Tag group has tags associated with it. Only empty tag groups can be deleted',
                    );
                    return;
                  }

                  api
                    .deleteTagGroup(tg.id)
                    .then(() => {
                      alert('Tag group was deleted');
                      onTagGroupDataChange();
                    })
                    .catch(() => {
                      alert(`Couldn't delete specified tag group`);
                    });
                }
              }}
              onEdit={() => {
                setEditingTagGroup({
                  tagGroupData: { ...tg },
                  isExisting: true,
                });
              }}
            />
          ))}
        </div>
      ) : (
        <div>
          {preparedTags.map((t) => (
            <TagEntityListItem
              key={t.id}
              item={t}
              onDelete={() => {
                if (
                  prompt(
                    `Are you sure you want to delete tag "${t.id}" (yes/no)`,
                  )?.toLowerCase() === 'yes'
                ) {
                  api.deleteTag(t.id);
                  onTagDataChange();
                }
              }}
              onEdit={() => {
                setEditingTagInfo({
                  tagData: { ...t },
                  isExisting: true,
                });
              }}
              firsColumnContent={
                <div
                  className={classes.tagEntityListItemValue}
                  title="Tag Group"
                >
                  📁 {t.tagGroupId}
                </div>
              }
            />
          ))}
        </div>
      )}

      {editingTagInfo.tagData ? (
        <EditTagEntity
          title="Add new tag"
          isExisting={editingTagInfo.isExisting}
          data={editingTagInfo.tagData}
          onSetData={(data) =>
            setEditingTagInfo((v) => ({
              ...v,
              tagData: {
                ...v.tagData,
                ...data,
              },
            }))
          }
          onClose={() => setEditingTagInfo({})}
          onSave={(data) => {
            if (!editingTagInfo.tagData?.tagGroupId) {
              alert('Tag group is not set');
              return;
            }

            setIsLoading(true);
            const request = editingTagInfo.isExisting
              ? api.updateTag({
                  ...getChanges(
                    tags.find(
                      (t) => t.id === editingTagInfo.tagData?.id,
                    ) as DbTag,
                    data,
                  ),
                  id: editingTagInfo.tagData.id,
                  type: DbItemType.tag,
                })
              : api.postTag({
                  ...data,
                  type: DbItemType.tag,
                  tagGroupId: editingTagInfo.tagData.tagGroupId,
                });

            request
              .then(() => {
                onTagDataChange();
              })
              .catch((e) => console.error(e))
              .finally(() => {
                setIsLoading(false);
                setEditingTagInfo({});
              });
          }}
          additionalContent={
            <div className={classes.editTagEntityRow}>
              <div className={classes.editTagEntityLabel}>Tag Group:</div>
              <select
                className={classes.editTagEntityInput}
                value={editingTagInfo.tagData.tagGroupId}
                onChange={(e) => {
                  setEditingTagInfo((v) => ({
                    ...v,
                    tagData: {
                      ...v.tagData,
                      tagGroupId: e.target.value,
                    },
                  }));
                }}
              >
                <option value="">{'<Not Selected>'}</option>
                {tagGroups
                  .filter((tg) => !tg.disabled)
                  .map((tg) => (
                    <option key={tg.id} value={tg.id}>
                      {tg.id}
                    </option>
                  ))}
              </select>
            </div>
          }
        />
      ) : null}

      {editingTagGroup.tagGroupData ? (
        <EditTagEntity
          title="Add new tag group"
          data={editingTagGroup.tagGroupData}
          onSetData={(data) =>
            setEditingTagGroup((v) => ({
              ...v,
              tagGroupData: {
                ...v.tagGroupData,
                ...data,
              },
            }))
          }
          isExisting={editingTagGroup.isExisting}
          onClose={() => setEditingTagGroup({})}
          onSave={(data) => {
            setIsLoading(true);
            api
              .postTagGroup({
                ...data,
                type: DbItemType.tagGroup,
              })
              .then(() => {
                onTagGroupDataChange();
              })
              .catch((e) => console.error(e))
              .finally(() => {
                setIsLoading(false);
                setEditingTagGroup({});
              });
          }}
        />
      ) : null}

      {isLoading && <Loader />}
    </div>
  );
};

function transformToIdValue(value: string): string {
  return value.replaceAll(' ', '-').replaceAll('_', '-');
}

function getChanges<T extends DbTagEntity>(original: T, updated: T) {
  return Object.entries(updated).reduce((result, [key, value]) => {
    if (original[key as keyof T] !== value) {
      result[key as keyof T] = value;
    }
    return result;
  }, {} as Partial<T>);
}
