import { createUseStyles } from 'react-jss';
import { theme } from '../../core/theme';

export const useEditRecordStyles = createUseStyles({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: 'white',
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '15px',
  },
  header: {
    backgroundColor: theme.headerColor,
    color: 'white',
    margin: '-15px -15px 10px',
    padding: 11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.headerHeight,
    boxSizing: 'border-box',
  },
  headerCloseButton: {
    width: 30,
    cursor: 'pointer',
    height: 30,
    borderRadius: '50%',
    backgroundColor: '#00000057',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
  },
  blockGeneralInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowLanguageRelatedData: {
    display: 'flex',
  },
  optionSelection: {
    display: 'flex',
    alignItems: 'center',
  },
  marginRight5px: {
    marginRight: '5px',
  },
  selectedTagsCaption: {
    marginLeft: '5px',
    borderLeft: '1px solid #000000b8',
    paddingLeft: '10px',
  },
  selectedTag: {
    border: '1px solid #0000002b',
    padding: '4px 8px',
    cursor: 'pointer',
    margin: '0 5px',
    borderRadius: '3px',
    userSelect: 'none',
    backgroundColor: '#6c6caf',
    color: 'white',
  },
  option: {
    border: '1px solid #0000002b',
    padding: '5px 15px',
    cursor: 'pointer',
    margin: '0 5px',
    borderRadius: '3px',
    userSelect: 'none',
  },
  optionSelected: {
    backgroundColor: '#6c6caf',
    color: 'white',
  },
  nestedColumnLanguageRelatedData: {
    flex: 1,
    border: '1px solid #0000002b',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    margin: '10px 10px 0 0',
    '&:last-child': {
      marginRight: '0px',
    },
    maxWidth: '33%',
  },
  uaTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rowControlButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 'auto',
  },
  controlButton: {
    marginLeft: '10px',
    padding: '5px 15px',
  },
});
