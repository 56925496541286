import { DbRecord, Language, PreparedRecord } from '@ds/shared-types';
import { useMemo } from 'react';
import { getDataForLanguage } from '../../utils/data.utils';
import { useStyles } from './List.styles';
import { ListRecord } from './ListRecord';

export const List: React.FC<{
  records: DbRecord[];
  lang: Language;
  onEdit: (id: DbRecord['id']) => void;
  onDelete: (id: DbRecord['id']) => void;
}> = ({ records, lang, onDelete, onEdit }) => {
  const classes = useStyles();

  const preparedRecords: PreparedRecord[] = useMemo(() => {
    return records.map((record) => {
      const preparedRecord: PreparedRecord = {
        id: record.id,
        tags: record.tags,
        files: getDataForLanguage(record, lang, 'files'),
        title: getDataForLanguage(record, lang, 'title'),
        text: getDataForLanguage(record, lang, 'text'),
        updatedAt: record.updatedAt,
      };
      return preparedRecord;
    });
  }, [records, lang]);

  return (
    <div className={classes.root}>
      {preparedRecords.map((record) => (
        <ListRecord
          key={record.id}
          record={record}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};
