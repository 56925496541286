import { createUseStyles } from 'react-jss';
import { theme } from '../core/theme';

export const useTagsStyles = createUseStyles({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: 'white',
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '15px',
  },
  header: {
    backgroundColor: theme.headerColor,
    color: 'white',
    margin: '-15px -15px 10px',
    padding: 11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.headerHeight,
    boxSizing: 'border-box',
  },
  blockHeaderLeftGroup: {
    display: 'flex',
  },
  caption: {
    marginRight: '50px',
  },
  headerCloseButton: {
    width: 30,
    cursor: 'pointer',
    height: 30,
    borderRadius: '50%',
    backgroundColor: '#00000057',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
  },
  blockHeaderViews: {
    display: 'flex',
    marginLeft: '50px',
  },
  buttonView: {
    width: '100px',
    backgroundColor: '#00000000',
  },
  buttonViewSelected: {
    backgroundColor: 'white',
  },
  buttonAdd: {
    marginRight: '5px',
  },

  editTagEntityRoot: {
    position: 'fixed',
    backgroundColor: '#efefef',
    border: '1px solid gray',
    left: '10px',
    top: `calc(${theme.headerHeight}px + 10px)`,
    height: '300px',
    width: '280px',
    boxShadow: '2px 2px 3px gray',
    display: 'flex',
    flexDirection: 'column',
  },
  editTagEntityTitle: {
    fontSize: 18,
    marginBottom: 15,
    borderBottom: '1px solid #00000029',
    paddingBottom: 10,
  },
  editTagEntityContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
  },
  editTagEntityRow: {
    display: 'flex',
    marginBottom: '5px',
  },
  editTagEntityLabel: {
    width: '90px',
  },
  editTagEntityInput: {
    width: '150px',
    flex: 1,
  },
  editTagEntityFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editTagEntityFooterButton: {
    margin: '5px 5px',
  },

  tagEntityListItemRoot: {
    display: 'flex',
    borderBottom: '1px solid #d1d1d1',
    '&:first-child': {
      borderTop: '1px solid #d1d1d1',
    },
  },
  tagEntityListItemValue: {
    display: 'flex',
    flex: 1,
    borderRight: '1px solid #d1d1d1',
    padding: '10px 15px',
    '&:first-child': {
      borderLeft: '1px solid #d1d1d1',
    },
  },
  tagEntityListItemValueHalfWidth: {
    flex: 0.5,
  },
  tagEntityListItemValueThirdOfWidth: {
    flex: 0.33,
  },
});
