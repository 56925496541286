import { DbRecord, DbTag, DbTagGroup, Language } from '@ds/shared-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { api } from '../core/api';
import auth from '../core/auth';
import { csn } from '../utils/csn.utils';
import {
  extractFileNameWithoutPath,
  getFileFunctions,
} from '../utils/file.utils';
import { useStyles } from './AdminPanelApp.styles';
import { EditRecord } from './EditRecord';
import { List } from './List/List';
import { Tags } from './Tags';

const changeScrollInHtmlBody = ({
  classDisabled,
  enable,
}: {
  enable: boolean;
  classDisabled: string;
}) => {
  const classList =
    window.document.getElementsByTagName('body')?.[0]?.classList;
  enable
    ? classList?.remove(classDisabled)
    : classList?.add(classDisabled);
};

function App() {
  const classes = useStyles();
  const [records, setRecords] = useState<DbRecord[]>([]);
  const [tagGroups, setTagGroups] = useState<DbTagGroup[]>([]);
  const [tags, setTags] = useState<DbTag[]>([]);

  const [editRecordState, setEditRecordState] = useState<{
    open: boolean;
    recordToEdit?: DbRecord | null;
  }>({ open: false, recordToEdit: null });

  const [tagsPageIsOpen, setTagsPageIsOpen] = useState(false);

  const [lang, setLang] = useState(Language.en);
  const [filterText, setFilterText] = useState('');
  const filterTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isInvalidating, setIsInvalidating] = useState(false);

  useEffect(() => {
    if (auth.isAuthorized) {
      Promise.all([
        api.getAllRecords(),
        api.getAllTagGroups(),
        api.getAllTags(),
      ]).then(([records, tagGroups, tags]) => {
        setRecords(records.data);
        setTagGroups(tagGroups.data);
        setTags(tags.data);
      });
    }
  }, []);

  const filteredRecords: DbRecord[] = useMemo(() => {
    const preparedFilterText = filterText.toLowerCase();
    return records.filter((i) => {
      return Object.values(i)
        .join(' ')
        .toLocaleLowerCase()
        .includes(preparedFilterText);
    });
  }, [filterText, records]);

  const handleOpenAddRecordDialog = useCallback(() => {
    changeScrollInHtmlBody({
      enable: false,
      classDisabled: classes.overflowHidden,
    });

    setEditRecordState({
      open: true,
      recordToEdit: null,
    });
  }, [setEditRecordState, classes.overflowHidden]);

  const handleOpenTagsPage = useCallback(() => {
    changeScrollInHtmlBody({
      enable: false,
      classDisabled: classes.overflowHidden,
    });
    setTagsPageIsOpen(true);
  }, [setTagsPageIsOpen, classes.overflowHidden]);

  const handleCloseTagsPage = useCallback(() => {
    changeScrollInHtmlBody({
      enable: true,
      classDisabled: classes.overflowHidden,
    });
    setTagsPageIsOpen(false);
  }, [setTagsPageIsOpen, classes.overflowHidden]);

  const handleUpdateTagData = useCallback(() => {
    api.getAllTags().then(({ data }) => setTags(data));
  }, []);

  const handleUpdateTagGroupData = useCallback(() => {
    api.getAllTagGroups().then(({ data }) => setTagGroups(data));
  }, []);

  const handleOpenEditRecordDialog = useCallback(
    (id: DbRecord['id']) => {
      const recordToEdit = records.find((i) => i.id === id);
      if (!recordToEdit) {
        console.error('cannot find record with id', id);
        return;
      }

      changeScrollInHtmlBody({
        enable: false,
        classDisabled: classes.overflowHidden,
      });

      setEditRecordState({
        open: true,
        recordToEdit: recordToEdit,
      });
    },
    [setEditRecordState, classes.overflowHidden, records],
  );

  const handleDelete = useCallback(
    async (id: DbRecord['id']) => {
      try {
        const recordToDelete = records.find((i) => i.id === id);
        if (!recordToDelete) {
          throw new Error(`Cannot file name with id ${id}`);
        }
        const { deleteFile } = await getFileFunctions();

        const deletePromises: Promise<unknown>[] = [];
        [
          ...recordToDelete.filesEn,
          ...recordToDelete.filesUa,
          ...recordToDelete.filesRu,
        ].forEach((f) =>
          deletePromises.push(deleteFile(extractFileNameWithoutPath(f))),
        );

        const deleteDbRecord = api.deleteRecord(id);

        await Promise.all([...deletePromises, deleteDbRecord]);

        const newRecords = records.filter((i) => i.id !== id);
        setRecords(newRecords);
      } catch (error) {
        console.error(error);
      }
    },
    [records],
  );

  const handleCloseEditRecordDialog = useCallback(
    (processedRecord?: DbRecord) => {
      changeScrollInHtmlBody({
        enable: true,
        classDisabled: classes.overflowHidden,
      });

      if (processedRecord) {
        const newRecords = editRecordState.recordToEdit
          ? records.map((record) => {
              if (record.id === processedRecord.id) {
                return processedRecord;
              }
              return record;
            })
          : [...records, processedRecord];

        setRecords(newRecords);
      }

      setEditRecordState({
        open: false,
        recordToEdit: null,
      });
    },
    [classes.overflowHidden, editRecordState.recordToEdit, records],
  );

  const handleInvalidation = useCallback(async () => {
    if (
      prompt(
        'Are you sure you want to invalidate WebUi Cache?',
      )?.toLowerCase() !== 'yes'
    ) {
      return;
    }

    const info = await api.createInvalidation();
    const id = info.data?.result.Invalidation.Id;
    if (!id) {
      alert(`Something went wrong. Couldn't get invalidation ID`);
      return;
    }

    try {
      setIsInvalidating(true);

      let timeout = false;
      const timeoutObj = setTimeout(() => (timeout = true), 300000);
      // eslint-disable-next-line no-constant-condition
      while (!timeout) {
        await new Promise((r) => setTimeout(r, 10000));
        const checkResult = await api.getInvalidation(id);
        if (checkResult.data?.result.Invalidation.Status === 'Completed') {
          clearTimeout(timeoutObj);
          alert('✅ Invalidation finished successfully');
          return;
        }
      }
      alert('🔴 Invalidation Timed out');
    } catch (error) {
      alert('🔴 Error occurred during Invalidation');
    } finally {
      setIsInvalidating(false);
    }
  }, []);

  return auth.isAuthorized ? (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.leftHeaderGroup}>
          <button
            className={classes.buttonAddRecord}
            onClick={handleOpenAddRecordDialog}
          >
            ➕ Add record
          </button>

          <button
            className={classes.buttonTags}
            onClick={handleOpenTagsPage}
          >
            🏷️ Tags
          </button>

          <button
            className={csn([classes.buttonLang, lang !== Language.en])}
            onClick={() => setLang(Language.en)}
          >
            🇬🇧 EN
          </button>
          <button
            className={csn([classes.buttonLang, lang !== Language.ua])}
            onClick={() => setLang(Language.ua)}
          >
            🇺🇦 UA
          </button>
          <button
            className={csn([classes.buttonLang, lang !== Language.ru])}
            onClick={() => setLang(Language.ru)}
          >
            🇷🇺 RU
          </button>

          <button
            className={classes.buttonInvalidate}
            onClick={handleInvalidation}
          >
            ⚠️Invalidate
          </button>

          {isInvalidating && (
            <div className={classes.invalidateProgress} />
          )}
        </div>

        <input
          type="search"
          placeholder="Type text to filter"
          onChange={(e) => {
            if (filterTimeoutRef.current) {
              clearTimeout(filterTimeoutRef.current);
            }
            filterTimeoutRef.current = setTimeout(() => {
              setFilterText(e.target.value);
            }, 200);
          }}
        />

        <button className={classes.logInOutButton} onClick={auth.logout}>
          LOGOUT
        </button>
      </header>

      <List
        records={filteredRecords}
        lang={lang}
        onEdit={handleOpenEditRecordDialog}
        onDelete={handleDelete}
      />
      {editRecordState.open && (
        <EditRecord
          onClose={handleCloseEditRecordDialog}
          tags={tags}
          tagsGroups={tagGroups}
          recordToEdit={editRecordState.recordToEdit || undefined}
        />
      )}

      {tagsPageIsOpen ? (
        <Tags
          tags={tags}
          tagGroups={tagGroups}
          onClose={handleCloseTagsPage}
          onTagDataChange={handleUpdateTagData}
          onTagGroupDataChange={handleUpdateTagGroupData}
        />
      ) : null}
    </div>
  ) : (
    <div className={classes.notLoggedInRoot}>
      <button className={classes.logInOutButton} onClick={auth.login}>
        LOGIN
      </button>
    </div>
  );
}

export default App;
