import { DbRecord, PreparedRecord } from '@ds/shared-types';
import { useCallback } from 'react';
import { FilePreview } from '../FilePreview';
import {
  fileColumnWidth,
  fileHeight,
  useListRecordStyles,
} from './ListRecord.styles';

const MAX_TEXT_LENGTH = 600;

export const ListRecord: React.FC<{
  record: PreparedRecord;
  onEdit: (id: DbRecord['id']) => void;
  onDelete: (id: DbRecord['id']) => void;
}> = ({ record, onEdit, onDelete }) => {
  const classes = useListRecordStyles();

  const handleDelete = useCallback(() => {
    const confirmation = prompt(
      `Delete record with ID "${record.id}"?` +
        '\n\n[type "yes" to confirm]',
    )?.toLowerCase();
    if (confirmation !== 'yes') {
      return;
    }
    onDelete(record.id);
  }, [record.id, onDelete]);

  const handleEdit = useCallback(() => {
    onEdit(record.id);
  }, [record.id, onEdit]);

  const preparedText =
    record.text.length > MAX_TEXT_LENGTH
      ? `${record.text.slice(0, MAX_TEXT_LENGTH)}...`
      : record.text;

  return (
    <div className={classes.root}>
      <div className={classes.columnGeneralInfo}>
        <div className={classes.titleBlock} title="Title">
          {record.title}
        </div>

        <div className={classes.tagsBlock} title="Tags">
          <div>Tags: </div>
          <div className={classes.tags}>
            {(record.tags || []).join(', ')}
          </div>
        </div>

        <div className={classes.recordId}>ID: {record.id}</div>
        <div className={classes.rowControlButtons}>
          <button onClick={handleEdit}>Edit</button>
          <button onClick={handleDelete}>Delete</button>
        </div>
      </div>

      <div className={classes.textBlock}>{preparedText}</div>

      <div className={classes.columnFiles}>
        <FilePreview
          file={record.files?.[0] || ''}
          height={fileHeight}
          width={fileColumnWidth}
        />
        {(record.files?.length || 1) - 1 ? (
          <div className={classes.filesNumber}>{`+${
            record.files.length - 1
          }`}</div>
        ) : null}
      </div>
    </div>
  );
};
