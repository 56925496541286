import { createUseStyles } from 'react-jss';
import { theme } from '../core/theme';

export const useStyles = createUseStyles({
  root: {
    paddingTop: `${theme.headerHeight}px`,
    minWidth: '700px',
  },
  notLoggedInRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage:
      'linear-gradient(50deg, hsl(70deg 98% 59%) 0%, hsl(92deg 99% 67%) 11%, hsl(128deg 96% 70%) 22%, hsl(159deg 100% 49%) 33%, hsl(168deg 100% 47%) 44%, hsl(177deg 100% 46%) 56%, hsl(184deg 100% 47%) 67%, hsl(190deg 100% 50%) 78%, hsl(193deg 100% 50%) 89%, hsl(196deg 100% 50%) 100%)',
  },
  logInOutButton: {
    padding: '5px 20px',
    cursor: 'pointer',
  },
  header: {
    minWidth: '700px',
    backgroundColor: theme.headerColor,
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    zIndex: 9,
    left: 0,
    top: 0,
    right: 0,
    boxSizing: 'border-box',
    height: `${theme.headerHeight}px`,
  },
  leftHeaderGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonAddRecord: {
    marginRight: '15px',
  },
  buttonLang: {
    backgroundColor: '#00000000',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  buttonInvalidate: {
    marginLeft: '15px',
  },
  invalidateProgress: {
    height: '10px',
    width: '10px',
    marginLeft: '3px',
    borderRadius: '50%',
    animation: '$invalidationRunning 0.5s alternate infinite',
    backgroundColor: '#000',
  },
  '@keyframes invalidationRunning': {
    '0%': {
      backgroundColor: '#000',
    },
    '100%': {
      backgroundColor: '#efff00',
    },
  },
  buttonTags: {
    marginRight: '10px',
  },
});
