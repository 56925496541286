import { createUseStyles } from 'react-jss';

export const fileColumnWidth = 250;
export const fileHeight = 250;

const separatorColor = '#00000026';

export const useListRecordStyles = createUseStyles({
  root: {
    marginBottom: '10px',
    padding: '15px 10px',
    borderRadius: '4px',
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 4px #0000007d',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#f3ecff7d',
    },
  },
  recordId: {
    marginTop: 'auto',
    fontSize: 12,
    color: '#4b4b4b',
  },
  rowControlButtons: {
    padding: '5px 0',
  },
  columnGeneralInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px',
    borderRight: `1px solid ${separatorColor}`,
  },
  titleBlock: {
    borderBottom: `1px solid ${separatorColor}`,
    paddingBottom: '5px',
    marginBottom: '5px',
    fontSize: 14,
    color: '#1b00ed',
    display: 'flex',
    justifyContent: 'space-between',
  },
  textBlock: {
    flex: 1,
    textAlign: 'justify',
    fontSize: '14px',
    padding: '0 10px',
    borderRight: `1px solid ${separatorColor}`,
    marginRight: '10px',
    width: 0,
    wordWrap: 'break-word',
    whiteSpace: 'break-spaces',
  },

  tagsBlock: {
    display: 'flex',
    cursor: 'pointer',
  },
  tags: {
    fontStyle: 'italic',
    marginLeft: '5px',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  columnFiles: {
    minWidth: `${fileColumnWidth}px`,
    maxWidth: `${fileColumnWidth}px`,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  filesNumber: {
    right: 2,
    width: 40,
    bottom: 2,
    height: 40,
    position: 'absolute',
    backgroundColor: '#000000ad',
    fontSize: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    border: '1px solid #ffffff54',
    color: '#fff',
  },
  blockPointerEvents: {
    pointerEvents: 'none',
  },
});
