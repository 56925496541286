import { createUseStyles } from 'react-jss';

export const useLoaderStyles = createUseStyles({
  loaderDualRingRoot: {
    position: 'fixed',
    zIndex: 9999,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#f5f5f5bd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  loaderDualRing: {
    display: 'inline-block',
    width: 80,
    height: 80,
    '&:after': {
      content: '" "',
      display: 'block',
      width: 64,
      height: 64,
      margin: 8,
      borderRadius: '50%',
      border: '6px solid #ccc',
      borderColor: '#3857c9 #3636360a #3857c9 #3636360a',
      animation: '$loaderDualRingAnim 1.2s linear infinite',
    },
  },

  '@keyframes loaderDualRingAnim': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});
