import { useLoaderStyles } from './Loader.styles';

export const Loader: React.FC = () => {
  const classes = useLoaderStyles();

  return (
    <div className={classes.loaderDualRingRoot}>
      <div className={classes.loaderDualRing} />
    </div>
  );
};
