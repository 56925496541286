import { DbRecord } from '@ds/shared-types';
import { FileInfo, RecordInfo } from './EditRecord.types';
import { postRecord } from './postRecord';
import { updateRecord } from './updateRecord';

export async function submitRecord({
  recordInfo,
  recordToEdit,
  filesRu,
  filesEn,
  filesUa,
}: {
  recordInfo: RecordInfo;
  recordToEdit?: DbRecord;
  filesEn: FileInfo[];
  filesUa: FileInfo[];
  filesRu: FileInfo[];
}): Promise<{ success: boolean; processedRecord?: DbRecord }> {
  try {
    if (!recordInfo.tags?.length) {
      throw new Error('There has to be at least one tag');
    }

    if (
      !recordInfo.textEn ||
      !recordInfo.textRu ||
      !recordInfo.titleEn ||
      !recordInfo.titleRu
    ) {
      throw new Error('Title or text is missing');
    }

    if (!filesRu.length) {
      throw new Error('Files are not selected');
    }

    let processedRecord: DbRecord;

    if (recordToEdit) {
      processedRecord = await updateRecord({
        recordInfo: recordInfo,
        recordToEdit: recordToEdit,
        filesEn,
        filesUa,
        filesRu,
      });
    } else {
      processedRecord = await postRecord({
        recordInfo: recordInfo,
        filesEn,
        filesUa,
        filesRu,
      });
    }

    return { success: true, processedRecord };
  } catch (error) {
    const errorMessage: string =
      (error as Error).message || (error as string);
    console.error(errorMessage);
    alert(errorMessage);
    return { success: false };
  }
}
